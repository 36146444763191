import React from "react"
import { graphql, Link } from "gatsby"
// import BackgroundImage from "gatsby-background-image"

import Layout from "../../components/layout/basic"
import SEO from "../../components/global/SEO"

// Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import CardDeck from "react-bootstrap/CardDeck"

import BlogCard from "../../components/blog/BlogCard"

const Blog = ({ data: { OgImage, articles } }) => {
  // const backgroundImage = [
  //   image.fluid,
  //   `radial-gradient(at 50% 100%, rgba(181, 45, 168, 0.6), rgba(15, 1, 94, 0.75))`,
  // ].reverse()

  return (
    <Layout>
      <SEO
        title="Блог компанії Новий Телеком"
        description="Читати Новини і Статті Інтернет-Провайдера Новий Телеком."
        url="/blog/"
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Блог
              </li>
            </ol>
          </nav>
          <h1 className="gradient-text">Блог провайдера Новий Телеком</h1>
          <div className="hero-slogan mb-4">Новини, акції, корисні статті</div>
        </Container>
      </div>

      <Container className="my-5">
        <Row>
          <CardDeck as="ul" className="list-unstyled">
            {articles.nodes.map(article => (
              <BlogCard
                key={article.id}
                articleImage={article.articleImage.fluid}
                articleTitle={article.articleTitle}
                articlePublishedDate={article.articlePublishedDate}
                articleExcerpt={
                  article.articleExcerpt.childMarkdownRemark.excerpt
                }
                articleSlug={article.seo.slug}
                articleCategories={article.articleCategory}
              />
            ))}
          </CardDeck>
        </Row>
      </Container>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  {
    image: contentfulAsset(id: { eq: "72cccbe9-87ae-5a4e-a9ef-a2063d504c4e" }) {
      title
      fluid(maxWidth: 1920) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    articles: allContentfulArticle(
      sort: { fields: articlePublishedDate, order: DESC }
      filter: { node_locale: { eq: "uk" } }
    ) {
      nodes {
        id
        articleTitle
        articlePublishedDate(formatString: "DD.MM.YYYY")
        articleExcerpt {
          childMarkdownRemark {
            excerpt(pruneLength: 150, truncate: false, format: PLAIN)
          }
        }
        seo {
          slug
          title
          metaDescription
        }
        articleImage {
          title
          description
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        articleCategory {
          articleCategoryName
          id
        }
      }
    }
  }
`
